import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import AILogo from "assets/images/ai_logo.png";
import { Copy, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
import rehypeRaw from "rehype-raw";

const Loader = ({ className }) => {
  return (
    <Loader2
      className={cn(" h-16 w-16 text-primary/60 animate-spin", className)}
    />
  );
};
export function AIMessage({ message }) {
  return (
    <div className="flex flex-row w-full gap-2 ">
      <img
        src={AILogo}
        alt="Logo"
        className="h-12 w-12 dark:text-gray-50 border-1 rounded-full"
      />
      <div className={`bg-white text-black rounded-2xl`}></div>
      <div className={`w-full text-black gap-2 relative group`}>
        <div className="pb-2 mb-1">
          {" "}
          <Markdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className="prose text-sm"
          >
            {message.message}
          </Markdown>
        </div>
        <div className="flex justify-start opacity-0 group-hover:opacity-100 transition-opacity">
          <Copy
            className="h-4 w-4 text-zinc-600 active:text-zinc-400"
            onClick={() => navigator.clipboard.writeText(message.message)}
          />
        </div>
      </div>
    </div>
  );
}
export function LastAIMessage({ message, showLoader }) {
  return (
    <>
      <div className="flex flex-row w-full gap-2 ">
        <img
          src={AILogo}
          alt="Logo"
          className="h-12 w-12 dark:text-gray-50 border-1 rounded-full"
        />
        {showLoader && message.length === 0 && (
          <div>
            <Loader className="w-10 h-10" />
          </div>
        )}
        {message.length > 0 && (
          <div className={`bg-white text-black rounded-2xl`}>
            <Markdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              className="prose text-sm"
            >
              {message}
            </Markdown>
          </div>
        )}
      </div>
    </>
  );
}
export function UserMessage({ message }) {
  return (
    <div className={`max-w-[70%] text-black gap-2 relative group`}>
      <div className="p-3 bg-zinc-200 rounded-2xl mb-1">{message.message}</div>
      <div className="flex justify-end opacity-0 group-hover:opacity-100 transition-opacity">
        <Copy className="h-4 w-4 text-zinc-600" />
      </div>
    </div>
  );
}
export function MessageList({ children, classname }) {
  return (
    <>
      <main
        className={cn(
          "flex-1 overflow-hidden flex flex-col w-[60%] mx-auto  bg-white p-2",
          classname
        )}
      >
        <div className="flex-1 overflow-y-auto no-scrollbar scroll-smooth">
          {children.length === 0 ? (
            <div className="h-full flex items-center justify-center text-zinc-500">
              <img
                src={AILogo}
                alt="Logo"
                className="h-20 w-20 dark:text-gray-50 text-zinc-500 border-1 opacity-40 rounded-full"
              />
            </div>
          ) : (
            <div className="space-y-4 pb-4 text-sm">{children}</div>
          )}
        </div>
      </main>
    </>
  );
}
