import axios from "axios";
import { Constants } from "@/constants";

// apiRequest now requires a token to be passed
const apiRequest = async (method, url, data = null, content_type, token) => {
  if (!token) {
    throw new Error("No authentication token provided");
  }

  try {
    const response = await axios({
      timeout: 300000,
      method,
      url: `${Constants.apiUrl}/${url}`,
      data,
      headers: {
        "Content-Type": content_type,
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(`Error with ${method} request to ${url}:`, error);
    
    // Handle authentication errors
    if (error.response && error.response.status === 401) {
      return {
        ...error.response,
        type: "auth",
        data: { 
          message: "Authentication failed. Please sign in again.",
          ...error.response.data 
        }
      };
    }
    
    // Handle other response errors
    if (error.response) {
      return { ...error.response, type: "response" };
    } else if (error.request) {
      return {
        data: { message: "No response from server" },
        status: 500,
        type: "request",
      };
    } else {
      return {
        data: { message: "Error setting up request" },
        status: 500,
        type: "other",
      };
    }
  }
};

// The getToken function should be passed from the auth context
export const sendRequest = async (
  data,
  url,
  method = "post",
  content_type = "application/json",
  getToken = null
) => {
  let token;
  
  // Use provided getToken function for fresh token or fallback to sessionStorage
  if (getToken) {
    token = await getToken();
    if (!token) {
      return {
        data: { message: "Failed to acquire authentication token" },
        status: 401,
        type: "auth",
      };
    }
  } else {
    // Fallback to stored token if getToken not provided
    token = sessionStorage.getItem("access_token");
    if (!token) {
      return {
        data: { message: "No authentication token available" },
        status: 401,
        type: "auth",
      };
    }
  }

  const response = await apiRequest(method, url, data, content_type, token);
  
  // If we get an auth error with the current token, try one more time with a fresh token
  if (response.type === "auth" && getToken) {
    // Force a fresh token acquisition
    sessionStorage.removeItem("access_token");
    const freshToken = await getToken();
    
    if (freshToken) {
      return await apiRequest(method, url, data, content_type, freshToken);
    }
  }
  
  return response;
};
