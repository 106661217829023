import { sendRequest } from "@/services/apiService";

// This is a mock data service that would be replaced with actual API calls in a real application

// Mock data for threads
const mockThreads = Array.from({ length: 50 }).map((_, i) => {
  const createdAt = new Date();
  createdAt.setDate(createdAt.getDate() - Math.floor(Math.random() * 30));

  const lastActive = new Date(createdAt);
  lastActive.setHours(lastActive.getHours() + Math.floor(Math.random() * 24));

  const models = ["gpt-4o", "gpt-4", "gpt-3.5-turbo"];
  const statuses = ["active", "completed", "archived"];

  return {
    id: `thread_${Math.random().toString(36).substring(2, 15)}`,
    user: `user${i + 1}@example.com`,
    status: statuses[Math.floor(Math.random() * statuses.length)],
    messageCount: Math.floor(Math.random() * 20) + 1,
    model: models[Math.floor(Math.random() * models.length)],
    createdAt: createdAt.toISOString(),
    lastActive: lastActive.toISOString(),
  };
});

// Function to get all threads with optional filtering
export async function getThreads(getToken) {
  try {
    const response = await sendRequest(
      null,
      "threads",
      "get",
      "application/json",
      getToken
    );

    // Transform the data to match the expected format in ThreadTable
    return response.data.threads.map((thread) => ({
      id: thread.thread_id,
      user: thread.email || "User",
      status: "active", // Default value
      messageCount: 0, // Default value
      model: "Unknown", // Default value
      assistant: thread.bot_name || "Unknown",
      createdAt: thread.created_at || new Date().toISOString(),
      lastActive: thread.created_at || new Date().toISOString(),
      title: thread.title || "Untitled Thread",
    }));
  } catch (error) {
    console.error("Error fetching threads:", error);
    return []; // Return empty array on error
  }
}

// Function to get thread statistics
export async function getThreadStats() {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 300));

  const totalThreads = mockThreads.length;
  const activeThreads = mockThreads.filter(
    (thread) => thread.status === "active"
  ).length;
  const totalMessages = mockThreads.reduce(
    (sum, thread) => sum + thread.messageCount,
    0
  );
  const averageMessages = totalMessages / totalThreads;
  const completedThreads = mockThreads.filter(
    (thread) => thread.status === "completed"
  ).length;
  const completionRate = completedThreads / totalThreads;

  return {
    totalThreads,
    activeThreads,
    averageMessages,
    completionRate,
  };
}

// Function to get a single thread by ID
export async function getThreadById(id) {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 200));

  return mockThreads.find((thread) => thread.id === id);
}
