import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "@/context/authContext";
import { Constants } from "@/constants";
import { SpinnerOverlay } from "@/components/ui/spinner-overlay";
import { ThreadSidebar } from "@/components/threads/thread-sidebar";
import { MessageArea } from "@/components/messages/message-area";
import { MessageInput } from "@/components/messages/message-input";
import { AuthErrorAlert } from "@/components/ui/auth-error";
import ModelSelector from "@/components/model-selector";
import {
  getThreadId as fetchThreadId,
  getThread as fetchThread,
  cancelRequest,
  uploadFiles,
} from "@/services/thread-service";
import {
  streamResponse,
  processMessageChunks,
} from "@/utils/message-processor";

function processMessageContent(content, location) {
  if (typeof content === "string") {
    try {
      const parsedContent = JSON.parse(content);

      if (
        parsedContent.type === "image_file" &&
        parsedContent.image_file &&
        parsedContent.image_file.file_id
      ) {
        return `![Image](${Constants.apiUrl}/files/${parsedContent.image_file.file_id}?type=image&bot_name=${location.state.bot_name})`;
      }

      if (
        parsedContent.type === "text" &&
        parsedContent.text &&
        parsedContent.text.annotations
      ) {
        let textValue = parsedContent.text.value || "";
        for (const key in parsedContent.text.annotations) {
          const element = parsedContent.text.annotations[key];
          if (element.type === "file_path") {
            textValue = textValue.replace(
              element.text,
              `${Constants.apiUrl}/files/${element.file_path.file_id}`
            );
          }
        }
        return textValue;
      }

      return JSON.stringify(parsedContent);
    } catch (e) {
      return content;
    }
  }

  if (typeof content === "object" && content !== null) {
    if (Array.isArray(content)) {
      return content
        .map((item) => {
          if (
            item.type === "image_file" &&
            item.image_file &&
            item.image_file.file_id
          ) {
            return `![Image](${Constants.apiUrl}/files/${item.image_file.file_id}?type=image&bot_name=${location.state.bot_name})`;
          } else if (item.type === "text") {
            if (item.text && item.text.annotations) {
              let textValue = item.text.value || "";
              for (const key in item.text.annotations) {
                const element = item.text.annotations[key];
                if (element.type === "file_path") {
                  textValue = textValue.replace(
                    element.text,
                    `${Constants.apiUrl}/files/${element.file_path.file_id}`
                  );
                }
              }
              return textValue;
            }
            return item.text.value || "";
          }
          return JSON.stringify(item);
        })
        .join("\n");
    }

    if (
      content.type === "image_file" &&
      content.image_file &&
      content.image_file.file_id
    ) {
      return `![Image](${Constants.apiUrl}/files/${content.image_file.file_id}?type=image)`;
    }

    return JSON.stringify(content);
  }

  return content || "";
}

export default function Home() {
  const { getToken, authError } = useAuth();
  const [threads, setThreads] = useState([]);
  const [message, setMessage] = useState("");
  const [showThreadLoader, setShowThreadLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState("");
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [selectedModel, setSelectedModel] = useState("gpt-4o");

  useEffect(() => {
    if (!location.state) {
      navigate("/", { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (location.state) {
      if (location.state.thread_id) {
        setThreadId(location.state.thread_id);
      }

      const finalString = location.state.data;
      if (finalString) {
        setMessages([
          {
            sender: "ai",
            message: finalString,
          },
        ]);
      }
    }
  }, [location.state]);

  const handleModelChange = (model) => {
    setSelectedModel(model);
  };

  const handleThreadSelect = async (thread, id) => {
    setThreadId(id);
    setIsFirstMessage(false);

    if (id) {
      try {
        setShowLoader(true);
        setError(null);

        const threadData = await fetchThread(id, getToken);
        if (threadData && Array.isArray(threadData)) {
          const threadInfo = threadData.find((msg) => msg.model);
          if (threadInfo && threadInfo.model) {
            setSelectedModel(threadInfo.model);
          }

          const formattedMessages = threadData.map((msg) => {
            let processedMessage = "";
            if (
              msg.content &&
              Array.isArray(msg.content) &&
              msg.content.length > 0
            ) {
              processedMessage = processMessageContent(msg.content, location);
            }

            return {
              sender: msg.role === "user" ? "user" : "ai",
              message: processedMessage,
              timestamp: new Date(msg.created_at * 1000).toISOString(),
              id: msg.id,
            };
          });

          formattedMessages.sort(
            (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
          );

          setMessages(formattedMessages);
        } else {
          setMessages([]);
        }
      } catch (error) {
        console.error("Error fetching thread:", error);
        setError("Failed to load conversation messages");
      } finally {
        setShowLoader(false);
      }
    } else {
      setMessages([]);
      setIsFirstMessage(true);
    }
  };

  const handleNewThread = async () => {
    setMessages([]);
    setMessage("");
    setError(null);
    setThreadId("");
    setShowThreadLoader(true);
    setIsFirstMessage(true);

    try {
      if (location.pathname === "/home") {
        navigate("/home", {
          state: {
            bot_name: location.state.bot_name,
            title: location.state.title,
            thread_id: "",
          },
          replace: true,
        });
      }
    } catch (error) {
      console.error("Error creating new thread:", error);
      setError("Failed to create new conversation. Please try again.");
    } finally {
      setShowThreadLoader(false);
    }
  };

  const handleSubmit = async (data, e, files) => {
    setError(null);

    try {
      let currentThreadId = threadId;
      let isNewThread = false;

      if (messages.length <= 0 && threadId.length <= 0) {
        setShowThreadLoader(true);
        try {
          currentThreadId = await fetchThreadId(
            location.state.bot_name,
            getToken,
            data.message
          );
          setThreadId(currentThreadId);
          isNewThread = true;
          setIsFirstMessage(true);
        } catch (error) {
          console.error("Error creating thread:", error);
          setShowThreadLoader(false);
          setError("Failed to create conversation. Please try again.");
          return;
        }
        setShowThreadLoader(false);
      }

      e.target.reset();
      setMessage("");

      const messageWithSender = {
        sender: "user",
        message: data.message,
        timestamp: new Date().toISOString(),
      };

      setShowLoader(true);
      setMessages((prev) => [...prev, messageWithSender]);

      if (isFirstMessage && currentThreadId) {
        setThreads((prev) => {
          const updatedThreads = [...prev];
          const threadIndex = updatedThreads.findIndex(
            (t) => t.thread_id === currentThreadId
          );

          if (threadIndex !== -1) {
            updatedThreads[threadIndex] = {
              ...updatedThreads[threadIndex],
              title: data.message,
            };
          } else {
            updatedThreads.unshift({
              thread_id: currentThreadId,
              title: data.message,
            });
          }

          return updatedThreads;
        });

        setIsFirstMessage(false);
      }

      let params = {
        message: data.message,
        bot_name: location.state.bot_name,
        model: selectedModel,
      };

      // Handle file uploads if any
      if (files.length > 0) {
        try {
          const { otherFileIds, imageIds } = await uploadFiles(
            files,
            location.state.bot_name,
            getToken
          );
          params["other_file_ids"] = otherFileIds;
          params["image_ids"] = imageIds;
        } catch (error) {
          console.error("Error uploading files:", error);
          setShowLoader(false);
          setError("Failed to upload files. Please try again.");
          return;
        }
      }

      // Get authentication token
      const token = await getToken();
      if (!token) {
        setShowLoader(false);
        setError("Authentication failed. Please sign in again.");
        return;
      }

      if (isFirstMessage) {
        params["title"] = data.message;
      }

      try {
        const response = await fetch(`${Constants.apiUrl}/bot`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...params,
            thread: currentThreadId,
          }),
        });

        if (!response.ok) {
          throw new Error(`API error: ${response.status}`);
        }

        let aiMessage = "";
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const val = decoder.decode(value, { stream: true });
          const arr = val.split("\n--END--\n").filter((ele) => ele);

          for (const i in arr) {
            try {
              let result = JSON.parse(arr[i]);
              if (result.type === "text") {
                if (result.text.value) {
                  aiMessage += result.text.value;
                } else {
                  for (const key in result.text.annotations) {
                    const element = result.text.annotations[key];
                    if (element.type === "file_path") {
                      aiMessage = aiMessage.replace(
                        element.text,
                        `${Constants.apiUrl}/files/${element.file_path.file_id}?bot_name=${location.state.bot_name})`
                      );
                    }
                  }
                }
              } else if (result.type === "image_file") {
                aiMessage += `![Image](${Constants.apiUrl}/files/${result.image_file.file_id}?type=image&bot_name=${location.state.bot_name})`;
              }
            } catch (error) {
              console.error("Error parsing chunk:", error);
            }
          }

          setMessage(aiMessage);
        }

        setShowLoader(false);
        setMessages((prev) => [
          ...prev,
          {
            sender: "ai",
            message: aiMessage,
            timestamp: new Date().toISOString(),
          },
        ]);

        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
        setShowLoader(false);
        setError("Failed to get response. Please try again.");
      }
    } catch (error) {
      console.error("Unhandled error in message submission:", error);
      setShowLoader(false);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  // Handle cancellation of ongoing request
  const handleCancel = async (e) => {
    e.preventDefault();
    try {
      await cancelRequest(threadId, location.state.bot_name, getToken);
    } catch (error) {
      console.error("Error cancelling request:", error);
      setError("Failed to cancel request");
    }
    setShowLoader(false);
  };

  if (!location.state) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showThreadLoader && <SpinnerOverlay />}
      <AuthErrorAlert />

      {error && !authError && (
        <div className="fixed top-4 right-4 w-96 z-50 bg-black border p-4 rounded shadow-md">
          <div className="text-white font-medium">Error</div>
          <div className="text-white">{error}</div>
          <button
            className="mt-2 text-sm text-blue-600"
            onClick={() => setError(null)}
          >
            Dismiss
          </button>
        </div>
      )}

      <div className="flex h-screen overflow-hidden">
        <ThreadSidebar
          botName={location.state?.bot_name}
          threads={threads}
          setThreads={setThreads}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          onThreadSelect={handleThreadSelect}
          onNewThread={handleNewThread}
        />

        <div className="flex flex-col flex-grow unybrands-bg-vlight overflow-hidden">
          <header className="flex justify-between items-center py-4 px-6">
            <div className="font-light text-2xl text-blue-500">
              {location.state?.title}
            </div>
            <ModelSelector
              selectedModel={selectedModel}
              onModelChange={handleModelChange}
            />
          </header>

          <MessageArea
            messages={messages}
            typingMessage={message}
            showLoader={showLoader}
          />

          <MessageInput
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            showLoader={showLoader}
            currentMessage={message}
            setCurrentMessage={setMessage}
          />
        </div>
      </div>
    </>
  );
}
