import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Plus, X } from "lucide-react";
import { useAuth } from "@/context/authContext";
import { getThreads, getThread } from "@/services/thread-service";

export function ThreadSidebar({
  botName,
  threads,
  setThreads,
  sidebarOpen,
  setSidebarOpen,
  onThreadSelect,
  onNewThread,
}) {
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThreads = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const fetchedThreads = await getThreads(botName, getToken);
        setThreads(fetchedThreads);
      } catch (error) {
        console.error("Error fetching threads:", error);
        setError("Failed to load conversations");
      } finally {
        setIsLoading(false);
      }
    };

    fetchThreads();
  }, [botName, getToken, setThreads]);

  const handleThreadClick = async (threadId) => {
    try {
      setIsLoading(true);
      const threadData = await getThread(threadId, getToken);
      onThreadSelect(threadData, threadId);
    } catch (error) {
      console.error("Error selecting thread:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewChat = () => {
    if (onNewThread) {
      onNewThread();
    } else {
      onThreadSelect(null, "");
    }
  };

  const truncateTitle = (title) => {
    if (!title) return "New conversation";
    return title.length > 30 ? title.substring(0, 30) + "..." : title;
  };

  return (
    <div
      className={`
      flex-none w-80 bg-white dark:bg-gray-700
      transform transition-transform duration-300 ease-in-out
      ${sidebarOpen ? "translate-x-0" : "-translate-x-full"}
      md:relative md:translate-x-0
    `}
    >
      <div className="flex flex-col h-full w-full">
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-4 right-4 md:hidden"
          onClick={() => setSidebarOpen(false)}
        >
          <X className="h-6 w-6" />
          <span className="sr-only">Close sidebar</span>
        </Button>

        <div className="p-4">
          <h2 className="text-lg font-semibold">Conversations</h2>
        </div>
        <div className="p-4">
          <Button
            className="w-full justify-start bg-black"
            variant="outline"
            onClick={handleNewChat}
            disabled={isLoading}
          >
            <Plus className="mr-2 h-4 w-4" />
            New chat
          </Button>
        </div>

        {error && <div className="px-4 py-2 text-red-500 text-sm">{error}</div>}

        <ScrollArea className="flex-grow">
          <div className="p-4 space-y-2">
            {isLoading && threads.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                Loading conversations...
              </div>
            ) : threads.length > 0 ? (
              threads.map((t, i) => (
                <Button
                  key={i}
                  variant="ghost"
                  value={t.thread_id}
                  className="w-full justify-start text-left font-normal overflow-hidden text-ellipsis whitespace-nowrap"
                  onClick={(e) => handleThreadClick(t.thread_id)}
                  disabled={isLoading}
                >
                  {truncateTitle(t.title)}
                </Button>
              ))
            ) : (
              <div className="text-center py-4 text-gray-500">
                No conversations yet
              </div>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
