import { sendRequest } from "./apiService";

export async function getThreadId(botName, getToken, title = "") {
  const res = await sendRequest(
    null,
    `thread_id?bot_name=${botName}&title=${encodeURIComponent(title)}`,
    "get",
    "application/json",
    getToken
  );

  if (res.type === "auth") {
    throw new Error("Authentication failed");
  }

  return res.data.thread_id;
}

export async function getThread(threadId, getToken) {
  const resp = await sendRequest(
    null,
    `thread?thread_id=${threadId}`,
    "get",
    "application/json",
    getToken
  );

  if (resp.type === "auth") {
    throw new Error("Authentication failed");
  }

  return resp.data;
}

export async function getThreads(botName, getToken) {
  const resp = await sendRequest(
    null,
    `threads?bot_name=${botName}`,
    "get",
    "application/json",
    getToken
  );

  if (resp.type === "auth") {
    throw new Error("Authentication failed");
  }

  if (resp.status === 200) {
    return resp.data.threads;
  }
  return [];
}

export async function cancelRequest(threadId, botName, getToken) {
  const resp = await sendRequest(
    null,
    `cancel?thread_id=${threadId}&bot_name=${botName}`,
    "get",
    "application/json",
    getToken
  );

  if (resp.type === "auth") {
    throw new Error("Authentication failed");
  }

  return resp;
}

export async function uploadFiles(files, botName, getToken) {
  const formData = new FormData();
  files.forEach((fileItem) => {
    formData.append("files", fileItem.obj, fileItem.name);
  });
  formData.append("bot_name", botName);

  const resp = await sendRequest(
    formData,
    "files",
    "post",
    "multipart/form-data",
    getToken
  );

  if (resp.type === "auth") {
    throw new Error("Authentication failed");
  }

  return {
    otherFileIds: resp.data.other_file_ids,
    imageIds: resp.data.image_ids,
  };
}
