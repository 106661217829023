import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const availableModels = [
  { id: "gpt-3.5-turbo", name: "GPT-3.5 Turbo" },
  { id: "gpt-4", name: "GPT-4" },
  { id: "gpt-4-turbo", name: "GPT-4 Turbo" },
  { id: "gpt-4o", name: "GPT-4o" },
];

function ModelSelector({ selectedModel, onModelChange }) {
  return (
    <div className="flex items-center space-x-2">
      <Select value={selectedModel} onValueChange={onModelChange}>
        <SelectTrigger className="w-[180px] h-9 bg-gray-100 dark:bg-gray-800 border-none">
          <SelectValue placeholder="Select a model" />
        </SelectTrigger>
        <SelectContent>
          {availableModels.map((model) => (
            <SelectItem key={model.id} value={model.id}>
              {model.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

export default ModelSelector;
