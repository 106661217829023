import { useEffect, useRef } from "react";
import { 
  MessageList, 
  UserMessage, 
  AIMessage, 
  LastAIMessage 
} from "@/components/core/messagelist";

export function MessageArea({ messages, typingMessage, showLoader }) {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const messagesContainer = messagesEndRef.current?.parentElement;
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messages]);

  return (
    <MessageList>
      {messages.map((msg, index) => (
        <div
          key={index}
          className={`flex ${
            msg.sender === "user" ? "justify-end" : "justify-start"
          }`}
        >
          {msg.sender === "user" ? (
            <UserMessage message={msg} />
          ) : (
            <AIMessage message={msg} />
          )}
        </div>
      ))}
      {showLoader && (
        <LastAIMessage message={typingMessage} showLoader={showLoader} />
      )}
      <div ref={messagesEndRef} />
    </MessageList>
  );
}