import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/home";
import "./assets/css/style.css";
import ReviewAnalysis from "./page/ReviewAnalysis.js";
import ReviewAnalysisDb from "./page/ReviewAnalysisDb.js";
import TitleGenerator from "./page/TitleGenerator.js";
import Header from "./components/includes/Header";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import AddAsin from "./page/AddAsin.js";
import { LandingPage } from "./page/landing-page";
import { Homepage } from "./page/homepage";
import NewFeatureBullet from "./page/feature-bullet-generator";
import NewCrud from "./page/NewCrud";
import AgentHome from "./page/agent";
import CustomerService from "./page/CustomerService";
import AdminPanel from "./page/threads-admin";

function App() {
  return (
    <BrowserRouter>
      <div className="h-screen overflow-hidden flex flex-col">
        <Header />
        <Routes>
          <Route path="/dashboard" element={<AdminPanel />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/results" element={<Home />} />
          <Route path="/agent" element={<AgentHome />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route
            path="/feature-bullet-generator"
            element={<NewFeatureBullet />}
          />
          <Route path="/title-generator" element={<TitleGenerator />} />

          <Route path="/add-asin" element={<AddAsin />} />

          <Route path="/review-analysis" element={<ReviewAnalysis />} />
          <Route path="/review-analysis-db" element={<ReviewAnalysisDb />} />

          <Route path="/admin" element={<NewCrud />} />
          <Route path="*" element={<p>You are lost</p>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
