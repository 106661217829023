import { Suspense, useState, useCallback } from "react";
import { StatsCards } from "@/components/stats-cards";
import ThreadTable from "@/components/thread-table";
import FilterBar from "@/components/filter-bar";
import { Skeleton } from "@/components/ui/skeleton";

export default function AdminPanel() {
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [assistantFilter, setAssistantFilter] = useState("all");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [refreshKey, setRefreshKey] = useState(0);
  const [assistants, setAssistants] = useState([]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
  }, []);

  const handleStatusFilter = useCallback((status) => {
    setStatusFilter(status);
  }, []);

  const handleAssistantFilter = useCallback((assistant) => {
    setAssistantFilter(assistant);
  }, []);

  const handleDateRangeChange = useCallback((range) => {
    setDateRange(range);
  }, []);

  const handleAssistantsChange = useCallback((newAssistants) => {
    setAssistants(newAssistants);
  }, []);

  const handleRefresh = useCallback(() => {
    // Increment the key to force a re-render of the ThreadTable component
    setRefreshKey((prev) => prev + 1);
  }, []);

  return (
    <div className="flex flex-col h-screen text-black">
      <div className="border-b">
        <div className="flex h-16 items-center px-4">
          <h1 className="text-lg font-semibold">AI Thread Admin Panel</h1>
        </div>
      </div>
      <div className="flex flex-col flex-grow p-8 pt-6 h-full overflow-hidden">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
        </div>
        <Suspense fallback={<StatsCardsSkeleton />}>
          <div className="mb-4">
            <StatsCards />
          </div>
        </Suspense>
        <div className="flex flex-col flex-grow h-full overflow-hidden">
          <div className="mb-4">
            <FilterBar
              onSearch={handleSearch}
              onStatusFilter={handleStatusFilter}
              onAssistantFilter={handleAssistantFilter}
              onDateRangeChange={handleDateRangeChange}
              onRefresh={handleRefresh}
              assistants={assistants}
              dateRange={dateRange}
            />
          </div>
          <div className="flex-grow h-full overflow-auto">
            <Suspense fallback={<ThreadTableSkeleton />}>
              <ThreadTable
                key={refreshKey}
                searchQuery={searchQuery}
                statusFilter={statusFilter}
                assistantFilter={assistantFilter}
                dateRange={dateRange}
                onAssistantsChange={handleAssistantsChange}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatsCardsSkeleton() {
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
      {Array.from({ length: 4 }).map((_, i) => (
        <Skeleton key={i} className="h-[125px] w-full" />
      ))}
    </div>
  );
}

function ThreadTableSkeleton() {
  return <Skeleton className="h-full w-full min-h-[500px]" />;
}
