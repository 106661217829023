/**
 * v0 by Vercel.
 * @see https://v0.dev/t/ZSekWaUVMem
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import AILogo from "assets/images/ai_logo.png";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import { sendRequest } from "../../services/apiService";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import { msalConfig, loginRequest } from "@/authentication/authConfig";
import PromptBank from "./PromptBank";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { LogOut } from "lucide-react";
import ThemeSwitch from "../core/themeswitch";
import { useAuth } from "@/context/authContext";

export default function Header() {
  const { getToken } = useAuth();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [rawPrompts, setRawPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const handleClose = () => setShow(false);

  const onChange = (v) => {
    const value = v.target.value.toLowerCase();
    let data = rawPrompts;
    if (value != "") {
      data = rawPrompts.filter((prompt) => {
        return (
          prompt.title.toLowerCase().includes(value) ||
          prompt.prompt.toLowerCase().includes(value) ||
          prompt.category.toLowerCase().includes(value)
        );
      });
    }
    const reduced = data.reduce((accumulator, prompt) => {
      let acc = accumulator[prompt["category"]] ?? [];
      acc.push(prompt);
      accumulator[prompt["category"]] = acc;
      return accumulator;
    }, {});
    setFilteredPrompts(reduced);
  };
  const handleShow = async () => {
    setShow(true);
    const response = await sendRequest(
      null,
      "prompts",
      "get",
      undefined,
      getToken
    );
    setRawPrompts(response.data);
    const reduced = response.data.reduce((accumulator, prompt) => {
      let acc = accumulator[prompt["category"]] ?? [];
      acc.push(prompt);
      accumulator[prompt["category"]] = acc;
      return accumulator;
    }, {});
    setFilteredPrompts(reduced);
  };

  const account = useAccount(accounts[0] || undefined);
  const signIn = async () => {
    await instance
      .loginPopup()
      .then((response) => {
        // Set the active account after login
        instance.setActiveAccount(response.account);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
    instance.initialize().then(() => {
      instance
        .acquireTokenSilent(loginRequest)
        .then((response) => {
          sessionStorage.setItem("access_token", response.accessToken);
        })
        .catch((error) => {
          console.error("Token acquisition failed:", error);
        });
    });
  };
  const signOut = () => {
    instance.logoutPopup();
    navigate("/");
  };

  return (
    <div className="unybrands-bg-light">
      <header className="flex px-4">
        <Link className="flex items-center " to="/home">
          <img src={AILogo} alt="Logo" className="h-14 w-14 text-gray-900" />
        </Link>
        <nav className="ml-auto flex items-center gap-4 sm:gap-6">
          <div className="hidden md:block">
            <AuthenticatedTemplate>
              <div className="ml-4 flex items-center md:ml-6">
                <Drawer direction="left" handleOnly>
                  <DrawerTrigger asChild>
                    <Button
                      variant="link"
                      className="text-black"
                      onClick={() => handleShow()}
                    >
                      Prompts
                    </Button>
                  </DrawerTrigger>
                  <DrawerContent className="h-screen top-0 left-0 right-auto mt-0 w-[500px] rounded-none text-black">
                    <ScrollArea className="h-screen p-4 ">
                      <Input
                        placeholder="Search"
                        className="focus-visible:ring-0"
                        onChange={onChange}
                      ></Input>
                      <PromptBank prompts={filteredPrompts} />
                    </ScrollArea>
                  </DrawerContent>
                </Drawer>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button className="relative rounded-full h-12 w-12 bg-background text-foreground">
                      <Avatar>
                        <AvatarImage alt="User avatar" />
                        <AvatarFallback>
                          {account ? account.name[0] : ""}
                        </AvatarFallback>
                      </Avatar>
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end" className="w-56">
                    <div className="p-2">
                      <Label className="font-semibold text-s p-0 m-0">
                        {" "}
                        {account ? account.name : ""}
                      </Label>
                      <Label className="text-xs">
                        {" "}
                        {account ? account.username : ""}
                      </Label>
                    </div>
                    <ThemeSwitch />
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => signOut()}>
                      <LogOut className="mr-2 h-4 w-4" />
                      <span>Log out</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Button onClick={() => signIn()}>Login</Button>
            </UnauthenticatedTemplate>
          </div>
        </nav>
      </header>
    </div>
  );
}
