import React from "react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useAuth } from "@/context/authContext";

export function AuthErrorAlert() {
  const { authError, clearAuthError, isAuthenticating, getToken } = useAuth();

  if (!authError) return null;

  return (
    <Alert className="fixed top-4 right-4 w-96 z-50 bg-black">
      <AlertTitle className="flex justify-between items-center">
        <span>Authentication Error</span>
        <Button
          variant="ghost"
          size="sm"
          className="h-6 w-6 p-0"
          onClick={clearAuthError}
          disabled={isAuthenticating}
        >
          <X className="h-4 w-4" />
        </Button>
      </AlertTitle>
      <AlertDescription>
        <p className="mb-2">{authError}</p>
        <Button
          variant="outline"
          className="w-full"
          onClick={() => getToken()}
          disabled={isAuthenticating}
        >
          {isAuthenticating ? "Signing in..." : "Sign in"}
        </Button>
      </AlertDescription>
    </Alert>
  );
}
