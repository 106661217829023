import { useState, useEffect, useMemo, forwardRef } from "react";
import { getThreads } from "@/lib/data";
import { formatDistanceToNow } from "date-fns";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  MoreHorizontal,
  Eye,
  BarChart2,
  Trash2,
  X,
  ChevronUp,
  ChevronDown,
} from "lucide-react";
import { useAuth } from "@/context/authContext";
import { MessageArea } from "@/components/messages/message-area";
import { getThread as fetchThread } from "@/services/thread-service";
import { Constants } from "@/constants";

const ASSISTANT_NAMES = {
  chat_service: "AI Assistant",
  sql_bot_chat_service: "SQL Agent",
  customer_chat_service: "CS Assistant",
  dev_chat_service: "Dev Assistant",
  compliance_chat_service: "Compliance Assistant",
};

const ThreadTable = forwardRef(
  (
    {
      searchQuery = "",
      statusFilter = "all",
      assistantFilter = "all",
      dateRange = { from: null, to: null },
      onAssistantsChange,
    },
    ref
  ) => {
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { getToken } = useAuth();
    const [selectedThread, setSelectedThread] = useState(null);
    const [threadMessages, setThreadMessages] = useState([]);
    const [threadLoading, setThreadLoading] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sortConfig, setSortConfig] = useState({
      key: "createdAt",
      direction: "desc",
    });

    // Extract unique assistants from threads with mapped names
    const uniqueAssistants = useMemo(() => {
      // Create a Map to ensure unique assistants
      const assistantMap = new Map();

      threads.forEach((thread) => {
        if (thread.assistant && !assistantMap.has(thread.assistant)) {
          assistantMap.set(thread.assistant, {
            value: thread.assistant,
            label: ASSISTANT_NAMES[thread.assistant] || thread.assistant,
          });
        }
      });

      // Convert Map values to array and sort by label
      return Array.from(assistantMap.values()).sort((a, b) =>
        a.label.localeCompare(b.label)
      );
    }, [threads]);

    // Notify parent component of assistants change
    useEffect(() => {
      onAssistantsChange?.(uniqueAssistants);
    }, [uniqueAssistants, onAssistantsChange]);

    useEffect(() => {
      // Fetch threads data asynchronously
      const fetchThreads = async () => {
        try {
          const result = await getThreads(getToken);
          setThreads(result);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching threads:", err);
          setError("Failed to load threads data");
          setLoading(false);
        }
      };

      fetchThreads();
    }, []);

    const requestSort = (key) => {
      let direction = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
    };

    // Function to get sort indicator
    const getSortIndicator = (key) => {
      if (sortConfig.key === key) {
        return sortConfig.direction === "asc" ? (
          <ChevronUp className="h-4 w-4 inline ml-1" />
        ) : (
          <ChevronDown className="h-4 w-4 inline ml-1" />
        );
      }
      return null;
    };

    // Function to process message content
    const processMessageContent = (content, botName) => {
      if (typeof content === "string") {
        try {
          const parsedContent = JSON.parse(content);

          if (
            parsedContent.type === "image_file" &&
            parsedContent.image_file &&
            parsedContent.image_file.file_id
          ) {
            return `![Image](${Constants.apiUrl}/files/${parsedContent.image_file.file_id}?type=image&bot_name=${botName})`;
          }

          if (
            parsedContent.type === "text" &&
            parsedContent.text &&
            parsedContent.text.annotations
          ) {
            let textValue = parsedContent.text.value || "";
            for (const key in parsedContent.text.annotations) {
              const element = parsedContent.text.annotations[key];
              if (element.type === "file_path") {
                textValue = textValue.replace(
                  element.text,
                  `${Constants.apiUrl}/files/${element.file_path.file_id}`
                );
              }
            }
            return textValue;
          }

          return JSON.stringify(parsedContent);
        } catch (e) {
          return content;
        }
      }

      if (typeof content === "object" && content !== null) {
        if (Array.isArray(content)) {
          return content
            .map((item) => {
              if (
                item.type === "image_file" &&
                item.image_file &&
                item.image_file.file_id
              ) {
                return `![Image](${Constants.apiUrl}/files/${item.image_file.file_id}?type=image&bot_name=${botName})`;
              } else if (item.type === "text") {
                if (item.text && item.text.annotations) {
                  let textValue = item.text.value || "";
                  for (const key in item.text.annotations) {
                    const element = item.text.annotations[key];
                    if (element.type === "file_path") {
                      textValue = textValue.replace(
                        element.text,
                        `${Constants.apiUrl}/files/${element.file_path.file_id}`
                      );
                    }
                  }
                  return textValue;
                }
                return item.text.value || "";
              }
              return JSON.stringify(item);
            })
            .join("\n");
        }

        if (
          content.type === "image_file" &&
          content.image_file &&
          content.image_file.file_id
        ) {
          return `![Image](${Constants.apiUrl}/files/${content.image_file.file_id}?type=image)`;
        }

        return JSON.stringify(content);
      }

      return content || "";
    };

    // Apply filtering and sorting to threads
    const filteredAndSortedThreads = useMemo(() => {
      // First apply filters
      let result = [...threads];

      // Apply search filter
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        result = result.filter((thread) => {
          // Search across all fields
          return Object.entries(thread).some(([key, value]) => {
            // Skip searching in complex objects or arrays
            if (value === null || value === undefined) return false;
            if (typeof value === "object") return false;

            // Convert value to string and check if it includes the query
            return String(value).toLowerCase().includes(query);
          });
        });
      }

      // Apply status filter
      if (statusFilter && statusFilter !== "all") {
        result = result.filter((thread) => thread.status === statusFilter);
      }

      // Apply assistant filter
      if (assistantFilter && assistantFilter !== "all") {
        result = result.filter(
          (thread) => thread.assistant === assistantFilter
        );
      }

      // Apply date range filter
      if (dateRange.from || dateRange.to) {
        result = result.filter((thread) => {
          const threadDate = new Date(thread.createdAt);
          if (dateRange.from && dateRange.to) {
            return threadDate >= dateRange.from && threadDate <= dateRange.to;
          } else if (dateRange.from) {
            return threadDate >= dateRange.from;
          } else if (dateRange.to) {
            return threadDate <= dateRange.to;
          }
          return true;
        });
      }

      // Apply sorting
      if (sortConfig.key) {
        result.sort((a, b) => {
          // Handle different data types
          let aValue = a[sortConfig.key];
          let bValue = b[sortConfig.key];

          // Special handling for dates
          if (
            sortConfig.key === "createdAt" ||
            sortConfig.key === "lastActive"
          ) {
            aValue = new Date(aValue).getTime();
            bValue = new Date(bValue).getTime();
          }

          // String comparison
          if (typeof aValue === "string" && typeof bValue === "string") {
            return sortConfig.direction === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }

          // Number comparison
          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        });
      }

      return result;
    }, [
      threads,
      searchQuery,
      statusFilter,
      assistantFilter,
      dateRange,
      sortConfig,
    ]);

    const handleViewThread = async (threadId, botName) => {
      setSelectedThread(threadId);
      setSidebarOpen(true);
      setThreadLoading(true);

      try {
        const threadData = await fetchThread(threadId, getToken);
        if (threadData && Array.isArray(threadData)) {
          const formattedMessages = threadData.map((msg) => {
            let processedMessage = "";
            if (
              msg.content &&
              Array.isArray(msg.content) &&
              msg.content.length > 0
            ) {
              processedMessage = processMessageContent(msg.content, botName);
            }

            return {
              sender: msg.role === "user" ? "user" : "ai",
              message: processedMessage,
              timestamp: new Date(msg.created_at * 1000).toISOString(),
              id: msg.id,
            };
          });

          formattedMessages.sort(
            (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
          );

          setThreadMessages(formattedMessages);
        } else {
          setThreadMessages([]);
        }
      } catch (error) {
        console.error("Error fetching thread:", error);
        setError("Failed to load conversation messages");
      } finally {
        setThreadLoading(false);
      }
    };

    const closeSidebar = () => {
      setSidebarOpen(false);
      setSelectedThread(null);
      setThreadMessages([]);
    };

    if (loading) {
      return <div className="text-center py-4">Loading threads data...</div>;
    }

    if (error) {
      return <div className="text-center py-4 text-red-500">{error}</div>;
    }

    return (
      <div className="flex flex-col h-full">
        <div className="flex-grow overflow-auto">
          <Table>
            <TableHeader className="sticky top-0 bg-background z-10">
              <TableRow>
                <TableHead
                  className="w-[100px] cursor-pointer"
                  onClick={() => requestSort("id")}
                >
                  Thread ID {getSortIndicator("id")}
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("title")}
                >
                  Title {getSortIndicator("title")}
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("user")}
                >
                  User {getSortIndicator("user")}
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("assistant")}
                >
                  Assistant {getSortIndicator("assistant")}
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("createdAt")}
                >
                  Created {getSortIndicator("createdAt")}
                </TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredAndSortedThreads.length > 0 ? (
                filteredAndSortedThreads.map((thread) => (
                  <TableRow key={thread.id}>
                    <TableCell className="font-medium">
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() =>
                          handleViewThread(thread.id, thread.assistant || "")
                        }
                      >
                        {thread.id.slice(0, 30)}...
                      </button>
                    </TableCell>
                    <TableCell>{thread.title || "Untitled"}</TableCell>
                    <TableCell>{thread.user}</TableCell>
                    <TableCell>
                      {ASSISTANT_NAMES[thread.assistant] || thread.assistant}
                    </TableCell>
                    <TableCell>
                      {formatDistanceToNow(new Date(thread.createdAt), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-right">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onClick={() =>
                              handleViewThread(
                                thread.id,
                                thread.assistant || ""
                              )
                            }
                          >
                            <Eye className="mr-2 h-4 w-4" />
                            View Details
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <BarChart2 className="mr-2 h-4 w-4" />
                            View Analytics
                          </DropdownMenuItem>
                          <DropdownMenuItem className="text-destructive">
                            <Trash2 className="mr-2 h-4 w-4" />
                            Delete Thread
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className="text-center">
                    {searchQuery
                      ? "No matching threads found"
                      : "No threads found"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {/* Thread Sidebar */}
        {sidebarOpen && (
          <div className="fixed inset-y-0 right-0 w-2/3 bg-white shadow-xl z-50 flex flex-col">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Thread Details</h2>
              <Button variant="ghost" size="icon" onClick={closeSidebar}>
                <X className="h-5 w-5" />
              </Button>
            </div>

            <div className="flex-grow overflow-auto p-4">
              {threadLoading ? (
                <div className="flex justify-center items-center h-full">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
              ) : (
                <MessageArea
                  messages={threadMessages}
                  typingMessage=""
                  showLoader={false}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ThreadTable;
