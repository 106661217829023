import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import Hero from "assets/images/hero2.svg";
import Hero3 from "assets/images/hero3.svg";
import { useIsAuthenticated } from "@azure/msal-react";
export function LandingPage() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-2">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-30">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                    Unlock the Power of AI
                  </h1>
                  <p className="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400">
                    Empower your team with our cutting-edge AI toolkit.
                    Streamline your workflows, boost productivity, and drive
                    innovation. Empower your team with our cutting-edge AI
                    toolkit. Streamline your workflows, boost productivity, and
                    drive innovation.
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row">
                  <Button href="#">Get Started</Button>
                  <Button href="#" variant="secondary">
                    Learn More
                  </Button>
                </div>
              </div>
              <img
                alt="Hero"
                className="shadow-md mx-auto aspect-video overflow-hidden rounded-xl object-cover sm:w-full lg:order-last lg:aspect-square"
                height="550"
                src={Hero}
                width="550"
              />
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 dark:bg-gray-800">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800">
                  Key Features
                </div>
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  Streamline Your Workflows
                </h2>
                <p className="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                  Our AI toolkit offers a suite of powerful features to help you
                  work smarter, not harder.
                </p>
              </div>
            </div>
            <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
              <div className="flex flex-col justify-center space-y-4">
                <ul className="grid gap-6">
                  <li>
                    <div className="grid gap-1">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full bg-gray-900 p-2 text-gray-50 dark:bg-gray-50 dark:text-gray-900">
                          <BotIcon className="h-5 w-5" />
                        </div>
                        <h3 className="text-xl font-bold">
                          Feature Bullet Generator
                        </h3>
                      </div>
                      <p className="text-gray-500 dark:text-gray-400">
                        Streamline your workflows with our AI-powered automation
                        tools.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="grid gap-1">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full bg-gray-900 p-2 text-gray-50 dark:bg-gray-50 dark:text-gray-900">
                          <CombineIcon className="h-5 w-5" />
                        </div>
                        <h3 className="text-xl font-bold">Title Optimizer</h3>
                      </div>
                      <p className="text-gray-500 dark:text-gray-400">
                        Foster seamless collaboration with our AI-powered tools.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="grid gap-1">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full bg-gray-900 p-2 text-gray-50 dark:bg-gray-50 dark:text-gray-900">
                          <InfoIcon className="h-5 w-5" />
                        </div>
                        <h3 className="text-xl font-bold">Review Analyzer</h3>
                      </div>
                      <p className="text-gray-500 dark:text-gray-400">
                        Unlock powerful insights with our AI-driven analytics.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="grid gap-1">
                      <div className="flex items-center gap-2">
                        <div className="rounded-full bg-gray-900 p-2 text-gray-50 dark:bg-gray-50 dark:text-gray-900">
                          <InfoIcon className="h-5 w-5" />
                        </div>
                        <h3 className="text-xl font-bold">Prompt Bank</h3>
                      </div>
                      <p className="text-gray-500 dark:text-gray-400">
                        Choose one from our carefully crafted list of prompts
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <img
                alt="Image"
                className="mx-auto aspect-video overflow-hidden rounded-xl object-cover object-center sm:w-full lg:order-last"
                height="310"
                src={Hero3}
                width="550"
              />
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container grid items-center gap-6 px-4 md:px-6 lg:grid-cols-2 lg:gap-10">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter md:text-4xl/tight">
                Empower Your Team
              </h2>
              <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Our AI toolkit provides the tools and insights your team needs
                to drive innovation and boost productivity.
              </p>
            </div>
            <div className="flex items-center justify-end gap-2 min-[400px]:flex-row lg:justify-end">
              <Button href="#">Get Started</Button>
              <Button variant="secondary" href="#">
                Learn More
              </Button>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © 2024 AI Toolkit. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link
            className="text-xs hover:underline underline-offset-4 text-gray-700 dark:text-gray-400 transition-colors"
            href="#"
          >
            Terms of Service
          </Link>
          <Link
            className="text-xs hover:underline underline-offset-4 text-gray-700 dark:text-gray-400 transition-colors"
            href="#"
          >
            Privacy Policy
          </Link>
        </nav>
      </footer>
    </div>
  );
}

function BotIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  );
}

function CombineIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="8" height="8" x="2" y="2" rx="2" />
      <path d="M14 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2" />
      <path d="M20 2c1.1 0 2 .9 2 2v4c0 1.1-.9 2-2 2" />
      <path d="M10 18H5c-1.7 0-3-1.3-3-3v-1" />
      <polyline points="7 21 10 18 7 15" />
      <rect width="8" height="8" x="14" y="14" rx="2" />
    </svg>
  );
}

function InfoIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 16v-4" />
      <path d="M12 8h.01" />
    </svg>
  );
}
