import React, { createContext, useContext, useCallback, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authentication/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const { instance, accounts } = useMsal();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authError, setAuthError] = useState(null);

  const getToken = useCallback(async () => {
    if (accounts.length === 0) {
      setAuthError("No account found. Please sign in.");
      try {
        setIsAuthenticating(true);
        const loginResponse = await instance.loginPopup(loginRequest);
        setIsAuthenticating(false);
        if (loginResponse && loginResponse.accessToken) {
          sessionStorage.setItem("access_token", loginResponse.accessToken);
          sessionStorage.setItem("idToken", loginResponse.idToken);
          setAuthError(null);
          return loginResponse.accessToken;
        }
      } catch (error) {
        setIsAuthenticating(false);
        setAuthError("Failed to sign in. Please try again.");
        console.error("Login error:", error);
        return null;
      }
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      sessionStorage.setItem("access_token", response.accessToken);
      sessionStorage.setItem("idToken", response.idToken);
      setAuthError(null);
      return response.accessToken;
    } catch (error) {
      console.error("Token acquisition error:", error);
      
      // If interaction is required, prompt user to login again
      if (error instanceof InteractionRequiredAuthError) {
        setAuthError("Your session has expired. Please sign in again.");
        try {
          setIsAuthenticating(true);
          const loginResponse = await instance.loginPopup(loginRequest);
          setIsAuthenticating(false);
          if (loginResponse && loginResponse.accessToken) {
            sessionStorage.setItem("access_token", loginResponse.accessToken);
            sessionStorage.setItem("idToken", loginResponse.idToken);
            setAuthError(null);
            return loginResponse.accessToken;
          }
        } catch (popupError) {
          setIsAuthenticating(false);
          setAuthError("Failed to sign in. Please try again.");
          console.error("Login popup error:", popupError);
        }
      } else {
        setAuthError("Authentication error. Please try signing in again.");
      }
      return null;
    }
  }, [instance, accounts]);

  const signOut = useCallback(async () => {
    try {
      const logoutRequest = {
        account: instance.getActiveAccount() || (accounts.length > 0 ? accounts[0] : null),
        postLogoutRedirectUri: window.location.origin,
      };
      
      if (logoutRequest.account) {
        await instance.logoutRedirect(logoutRequest);
      } else {
        // If no account is found, just clear storage
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("idToken");
        window.location.reload();
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Clear session storage regardless of logout error
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("idToken");
      window.location.reload();
    }
  }, [instance, accounts]);

  return (
    <AuthContext.Provider value={{ 
      getToken, 
      signOut, 
      isAuthenticating, 
      authError, 
      clearAuthError: () => setAuthError(null) 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
