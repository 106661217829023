import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { CirclePause, File, Image, Plus, Send, X } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export function MessageInput({ 
  onSubmit, 
  onCancel, 
  showLoader, 
  currentMessage, 
  setCurrentMessage 
}) {
  const [files, setFiles] = useState([]);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const formRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const newHeight = Math.min(textarea.scrollHeight, 24 * 10);
      textarea.style.height = `${newHeight}px`;
    }
  }, [currentMessage]);

  const handleInputChange = (e) => {
    setCurrentMessage(e.target.value);
  };

  const onTextareaKeydown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  const onFileChange = (e) => {
    const files = e.target.files;
    if (files) {
      const newFiles = Array.from(files).map((file) => ({
        id: Date.now() + Math.random(),
        name: file.name,
        type: file.type.startsWith("image/") ? "image" : "file",
        obj: file,
      }));
      setFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const handleRemoveFile = (id) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
  };

  const { ref, ...rest } = register("message");
  const { ref: fileRef, ...fileRest } = register("files");

  const handleFormSubmit = (data, e) => {
    onSubmit(data, e, files);
    setFiles([]);
  };

  return (
    <footer className="pb-4 rounded-3xl drop-shadow-md">
      <form onSubmit={handleSubmit(handleFormSubmit)} ref={formRef} className="">
        <Card className="max-w-[60%] mx-auto bg-zinc-100 border-0 rounded-none rounded-b-lg">
          <div className="flex flex-col p-2 gap-2">
            {files.length > 0 && (
              <div className="flex flex-wrap gap-2">
                {files.map((file) => (
                  <div
                    key={file.id}
                    className="flex items-center bg-zinc-400 text-zinc-800 rounded px-2 py-1"
                  >
                    {file.type === "image" ? (
                      <Image className="h-4 w-4 mr-2" />
                    ) : (
                      <File className="h-4 w-4 mr-2" />
                    )}
                    <span className="text-sm truncate max-w-[150px]">
                      {file.name}
                    </span>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="ml-1 h-5 w-5 text-zinc-400 hover:text-zinc-100"
                      onClick={() => handleRemoveFile(file.id)}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <div className="flex p-2 gap-2">
              <textarea
                ref={(e) => {
                  ref(e);
                  textareaRef.current = e;
                }}
                className="flex-1 bg-transparent text-black placeholder-zinc-600 outline-none resize-none overflow-y-auto"
                onKeyDown={onTextareaKeydown}
                onInput={handleInputChange}
                placeholder="Message UB AI"
                disabled={showLoader}
                rows={1}
                {...rest}
                style={{ minHeight: "24px", maxHeight: "240px" }}
              />
            </div>
            <Input
              ref={(e) => {
                fileRef(e);
                fileInputRef.current = e;
              }}
              multiple
              {...fileRest}
              className="text-sm text-grey-500 w-full h-24
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-0
              file:text-sm file:font-medium
              file:bg-blue-50 file:text-blue-700
              hover:file:cursor-pointer hover:file:bg-amber-50
              border-none
              hover:file:text-amber-700 hidden"
              name="file"
              type="file"
              placeholder={"TEXT"}
              onChange={onFileChange}
              data-testid="file-input"
            />

            <div className="flex justify-between">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-black hover:bg-zinc-500 rounded-md transition-colors"
                  >
                    <Plus className="h-5 w-5" />
                    <span className="sr-only">Add file or image</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="w-56 bg-zinc-800 text-zinc-100"
                >
                  <DropdownMenuItem
                    onClick={() => fileInputRef.current.click()}
                    className="hover:bg-zinc-700 focus:bg-zinc-700"
                  >
                    <File className="mr-2 h-4 w-4" />
                    <span>Upload File</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              {showLoader ? (
                <Button
                  variant="ghost"
                  size="icon"
                  className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                  onClick={onCancel}
                >
                  <CirclePause className="h-5 w-5" />
                </Button>
              ) : (
                <Button
                  variant="ghost"
                  type="submit"
                  size="icon"
                  className="text-zinc-400 hover:text-black hover:bg-white/10 rounded-md transition-colors"
                  disabled={currentMessage.length <= 0}
                >
                  <Send className="h-5 w-5" />
                  <span className="sr-only">Send message</span>
                </Button>
              )}
            </div>
          </div>
        </Card>
      </form>
    </footer>
  );
}